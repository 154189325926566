export const EasyWords = [
  "the ",
  "be ",
  "to ",
  "of ",
  "and ",
  "in ",
  "it ",
  "for ",
  "not ",
  "on ",
  "as ",
  "you ",
  "do ",
  "at ",
  "but ",
  "his ",
  "by ",
  "we ",
  "say ",
  "her ",
  "she ",
  "or ",
  "an ",
  "my ",
  "one ",
  "all ",
  "so ",
  "up ",
  "out ",
  "if ",
  "who ",
  "get ",
  "go ",
  "me ",
  "can ",
  "no ",
  "him ",
  "see ",
  "now ",
  "its ",
  "use ",
  "two ",
  "how ",
  "our ",
  "way ",
  "new ",
  "any ",
  "day ",
  "us ",
  "act ",
  "add ",
  "age ",
  "ago ",
  "air ",
  "all ",
  "am ",
  "an ",
  "and ",
  "any ",
  "are ",
  "arm ",
  "art ",
  "as ",
  "ask ",
  "at ",
  "bad ",
  "bag ",
  "be ",
  "bed ",
  "big ",
  "bit ",
  "box ",
  "boy ",
  "bus ",
  "but ",
  "buy ",
  "by ",
  "can ",
  "cap ",
  "car ",
  "cat ",
  "cry ",
  "cup ",
  "cut ",
  "day ",
  "die ",
  "do ",
  "dog ",
  "dry ",
  "ear ",
  "eat ",
  "egg ",
  "end ",
  "eye ",
  "far ",
  "fat ",
  "few ",
  "fit ",
  "fix ",
  "fly ",
  "for ",
  "fox ",
  "fun ",
  "get ",
  "go ",
  "god ",
  "gun ",
  "hat ",
  "he ",
  "hen ",
  "her ",
  "him ",
  "his ",
  "hit ",
  "hot ",
  "how ",
  "ice ",
  "if ",
  "in ",
  "is ",
  "it ",
  "its ",
  "job ",
  "key ",
  "leg ",
  "let ",
  "lie ",
  "lip ",
  "lot ",
  "low ",
  "man ",
  "map ",
  "may ",
  "me ",
  "mix ",
  "my ",
  "net ",
  "new ",
  "no ",
  "nor ",
  "not ",
  "now ",
  "of ",
  "off ",
  "oil ",
  "old ",
  "on ",
  "one ",
  "or ",
  "our ",
  "out ",
  "own ",
  "pan ",
  "pay ",
  "pen ",
  "per ",
  "pin ",
  "put ",
  "red ",
  "rob ",
  "run ",
  "sad ",
  "say ",
  "see ",
  "she ",
  "sit ",
  "six ",
  "sky ",
  "so ",
  "son ",
  "sun ",
  "tea ",
  "ten ",
  "the ",
  "tie ",
  "to ",
  "toe ",
  "too ",
  "top ",
  "try ",
  "up ",
  "use ",
  "was ",
  "way ",
  "we ",
  "wet ",
  "who ",
  "why ",
  "win ",
  "yet ",
  "you ",
];
