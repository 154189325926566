export const HardWords = [
  "abandon ",
  "achieve ",
  "receive ",
  "beautiful ",
  "queue ",
  "accessible ",
  "acoustic ",
  "committee ",
  "weather ",
  "privilege ",
  "comfortable ",
  "bachelor ",
  "cactus ",
  "daddy ",
  "eagle ",
  "factory ",
  "guitar ",
  "hockey ",
  "icecream ",
  "jacket ",
  "kitten ",
  "laptop ",
  "motorcycle ",
  "nurse ",
  "orange ",
  "piano ",
  "queen ",
  "robot ",
  "sister ",
  "truck ",
  "underwear ",
  "vacuum ",
  "wedding ",
  "xylophone ",
  "yacht ",
  "zebra ",
  "India ",
  "catapult ",
  "coconut ",
  "people ",
  "because ",
  "across ",
  "active ",
  "activity ",
  "afraid ",
  "already ",
  "always ",
  "amount ",
  "another ",
  "answer ",
  "anyone ",
  "anything ",
  "anytime ",
  "appear ",
  "around ",
  "arrive ",
  "attack ",
  "autumn ",
  "basket ",
  "beautiful ",
  "bedroom ",
  "behave ",
  "before ",
  "behind ",
  "besides ",
  "better ",
  "between ",
  "birthday ",
  "border ",
  "borrow ",
  "bottle ",
  "bottom ",
  "branch ",
  "breakfast ",
  "breathe ",
  "bridge ",
  "bright ",
  "brother ",
  "business ",
  "candle ",
  "careful ",
  "careless ",
  "central ",
  "century ",
  "certain ",
  "chance ",
  "change ",
  "cheese ",
  "chicken ",
  "children ",
  "chocolate ",
  "choice ",
  "choose ",
  "circle ",
  "clever ",
  "clothes ",
  "cloudy ",
  "coffee ",
  "collect ",
  "colour ",
  "comfortable ",
  "common ",
  "compare ",
  "complete ",
  "computer ",
  "condition ",
  "continue ",
  "control ",
  "copper ",
  "corner ",
  "correct ",
  "contain ",
  "country ",
  "course ",
  "cupboard ",
  "dangerous ",
  "daughter ",
  "decide ",
  "decrease ",
  "depend ",
  "destroy ",
  "develop ",
  "different ",
  "difficult ",
  "dinner ",
  "direction ",
  "discover ",
  "double ",
  "education ",
  "effect ",
  "either ",
  "electric ",
  "elephant ",
  "enough ",
  "entrance ",
  "escape ",
  "evening ",
  "everyone ",
  "everybody ",
  "examination ",
  "example ",
  "except ",
  "excited ",
  "exercise ",
  "expect ",
  "expensive ",
  "explain ",
  "extremely ",
  "family ",
  "famous ",
  "father ",
  "female ",
  "finger ",
  "finish ",
  "flower ",
  "football ",
  "foreign ",
  "forest ",
  "forget ",
  "forgive ",
  "freedom ",
  "freeze ",
  "friend ",
  "friendly ",
  "furniture ",
  "further ",
  "future ",
  "garden ",
  "general ",
  "gentleman ",
  "goodbye ",
  "grandfather ",
  "grandmother ",
  "ground ",
  "hammer ",
  "happen ",
  "healthy ",
  "heaven ",
  "height ",
  "holiday ",
  "hospital ",
  "hundred ",
  "hungry ",
  "husband ",
  "important ",
  "increase ",
  "inside ",
  "introduce ",
  "invent ",
  "invite ",
  "island ",
  "kitchen ",
  "ladder ",
  "lately ",
  "length ",
  "lesson ",
  "letter ",
  "library ",
  "listen ",
  "little ",
  "lonely ",
  "machine ",
  "market ",
  "matter ",
  "measure ",
  "medicine ",
  "member ",
  "mention ",
  "method ",
  "middle ",
  "million ",
  "minute ",
  "mistake ",
  "modern ",
  "moment ",
  "monkey ",
  "morning ",
  "mother ",
  "mountain ",
  "narrow ",
  "nation ",
  "nature ",
  "nearly ",
  "needle ",
  "neighbour ",
  "neither ",
  "newspaper ",
  "nothing ",
  "notice ",
  "number ",
  "object ",
  "office ",
  "opposite ",
  "orange ",
  "outside ",
  "parent ",
  "partner ",
  "pencil ",
  "people ",
  "pepper ",
  "perfect ",
  "period ",
  "person ",
  "petrol ",
  "photograph ",
  "picture ",
  "plastic ",
  "please ",
  "pleased ",
  "plenty ",
  "pocket ",
  "poison ",
  "police ",
  "polite ",
  "popular ",
  "position ",
  "possible ",
  "potato ",
  "present ",
  "pretty ",
  "prevent ",
  "prince ",
  "prison ",
  "private ",
  "probably ",
  "problem ",
  "produce ",
  "promise ",
  "proper ",
  "protect ",
  "provide ",
  "public ",
  "punish ",
  "question ",
  "really ",
  "receive ",
  "record ",
  "remember ",
  "remind ",
  "remove ",
  "repair ",
  "repeat ",
  "report ",
  "restaurant ",
  "result ",
  "return ",
  "rubber ",
  "school ",
  "science ",
  "scissors ",
  "search ",
  "second ",
  "sentence ",
  "several ",
  "shadow ",
  "should ",
  "shoulder ",
  "signal ",
  "silence ",
  "silver ",
  "similar ",
  "simple ",
  "single ",
  "sister ",
  "someone ",
  "something ",
  "sometimes ",
  "special ",
  "spread ",
  "spring ",
  "square ",
  "station ",
  "stomach ",
  "strange ",
  "street ",
  "strong ",
  "structure ",
  "student ",
  "stupid ",
  "subject ",
  "substance ",
  "successful ",
  "sudden ",
  "suitable ",
  "summer ",
  "support ",
  "surprise ",
  "telephone ",
  "television ",
  "tennis ",
  "terrible ",
  "therefore ",
  "though ",
  "threat ",
  "together ",
  "tomorrow ",
  "tonight ",
  "travel ",
  "trouble ",
  "understand ",
  "useful ",
  "usually ",
  "vegetable ",
  "village ",
  "weather ",
  "wedding ",
  "weight ",
  "welcome ",
  "window ",
  "winter ",
  "without ",
  "wonder ",
  "yesterday ",
];
